<template>
  <div class="bg-grid fixed top-0 left-0 w-full h-full pointer-events-none"></div>
  <Particles
    id="tsparticles"
    :particlesInit="particlesInit"
    :options="options"
  />
  <div class="relative">
    <router-view/>
  </div>
  <div class="fixed bottom-2 text-right w-full -z-1 px-3">
    <a href="https://dribbble.com/Pocike" target="_blank" class="text-[10px] md:text-xs opacity-60">Icons by Alpár Méder</a>
  </div>
</template>

<script setup lang="ts">
import { loadFull } from "tsparticles";

const options = {
  particles: {
    number: {
      value: 24,
      density: {
        enable: true,
        area: 800
      }
    },
    reduceDuplicates: true,
    rotate: {
      animation: {
        enable: true,
        speed: { min: 5, max: 8 }
      }
    },
    shape: {
      type: ["image"],
      image: Array.from(Array(5448876 - 5448841), (_, i) => i + 5448841)
      .filter(id => ![5448853, 5448866].includes(id))
      .map(id => ({
        src: `/icons/${id}.png`,
        height: 128,
        width: 128,
      })),
    },
    opacity: {
      value: 0.5
    },
    size: {
      value: 24
    },
    move: {
      enable: true,
      speed: 1.5,
      direction: "none",
      random: false,
      straight: false
    }
  },
  detectRetina: true,
}

const particlesInit = async (engine: any) => {
  await loadFull(engine);
};



</script>

<style lang="scss">
html, body {
  font-family: 'faire-sprig';
  background: url('assets/bgpattern.png') #ECE0D4;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.bg-grid {
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 178px,
    #D6C6B6 178px,
    #D6C6B6 179px
  )
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
