import { createApp } from 'vue'
import App from './App.vue'
import Particles from "vue3-particles";
import router from './router'

import './tailwind.css';

// @ts-ignore
createApp(App).use(router).use(Particles).mount('#app')

localStorage.setItem("Kees", "gek");
