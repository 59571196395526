<template>
  <div class="watch flex flex-col justify-center items-center w-screen h-screen px-2 pb-14 md:pb-0">
    <img src="../assets/happy-new-year.png" class="w-56 md:w-96" />
    <div class="w-full max-w-5xl px-4 py-6 lg:p-12 relative">
      <!-- <img src="../assets/frame-tl.svg" class="absolute top-0 left-0 w-96 max-w-[60%] pointer-events-none" />
      <img src="../assets/frame-br.svg" class="absolute bottom-0 w-96 right-0 max-w-[60%] pointer-events-none" /> -->
      <div class="aspect-video relative">
        <video controls class="w-full rounded-xl" poster="../assets/poster.png">
          <source :src="videoSrc" type="video/mp4" @error="e => onError()" />
        </video>
      </div>
    </div>
    <img src="../assets/theydo-santa.png" class="w-24 md:w-32" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter()
const route = useRoute()

if (!route.params.v || typeof route.params.v !== 'string') {
  router.push('/watch/r1ckr0ll')
}

const videoSrc = computed(() => `/videos/${route.params.v}.mp4`)

const onError = () => {
  if (route.params.v !== 'r1ckr0ll') {
    router.push('/watch/r1ckr0ll')
  }
}
</script>
